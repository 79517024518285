import { ReactElement } from "react";
import { Button } from "legacy-ui";
import Image from "next/image";
import MarketingLayout from "@/components/layout/marketing";
import wallE from "public/walle.gif";

export default function Custom404({}: {}) {
  return (
    <>
      <div className="mx-auto flex max-w-screen-laptop flex-col px-4 pb-14 pt-28 tablet:px-20 tablet:pb-28">
        <h1 className="mx-auto text-center text-legacy-4xl font-bold tablet:text-legacy-6xl">
          Lost your way?
        </h1>
        <p className="mx-auto mt-3 text-center text-legacy-2xl font-normal">
          Sorry, we can&apos;t find that page. You&apos;ll find lots to explore
          on the{" "}
          <Button variant="marketing_text_secondary_2xl" href="/">
            home page
          </Button>
          .
        </p>
        <Image
          src={wallE}
          alt="You might be a little lost, like Wall-E here."
          className="mx-auto mt-16 overflow-hidden rounded-[28px]"
        />
      </div>
    </>
  );
}

Custom404.getLayout = function getLayout(page: ReactElement) {
  return (
    <MarketingLayout title="404" footerVariant="preview">
      {page}
    </MarketingLayout>
  );
};
Custom404.theme = "light";
